<template>
  <div class="tool-dropdown"
    :class="{
      'tool-dropdown--open': isOpen,
      'tool-dropdown--align-left': dropdownAlign === 'left',
      'tool-dropdown--text': toggleBtnText,
      'tool-dropdown--kefta': !toggleBtnText,
    }"
    v-click-outside="closeDropdown"
  >

    <Btn
      class="btn-toggle-tool-panel"
      :round="!toggleBtnText"
      :grow="!toggleBtnText"
      :icon="toggleBtnText ? 'caret-down' : 'kefta-dots'"
      :iconSize="toggleBtnText ? 'xs' : 'md'"
      iconAfter
      :text="toggleBtnText"
      :color="toggleBtnText ? 'primary' : ''"
      @click="toggleDropdown"
    />

    <nav class="tool-dropdown-nav">
      <ul class="menu">
        <slot />
      </ul>
    </nav>

  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'ToolDropdown',

  components: {
    Btn,
  },

  props: {
    dropdownAlign: {
      type: String,
      default: 'right',
    },
    toggleBtnText: {
      type: String,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  methods: {
    toggleDropdown() {
      if (!this.isOpen) {
        // Opening dropdown
        this.isOpen = true
      } else {
        // Closing dropdown
        this.isOpen = false
      }
    },
    closeDropdown() {
      this.isOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
// TOOL MENU BUTTON ("kefta")

.tool-dropdown {
  position: relative;
}

.btn-toggle-tool-panel {
  height: 4rem;
  // border-radius: 50%;

  :deep(> .icon) {
    transition: all 0.25s;
  }
}

.tool-dropdown--text {
  .btn-toggle-tool-panel {
    width: auto;
  }

  // Active button
  &.tool-dropdown--open {
    .btn-toggle-tool-panel {

      :deep(> .icon) {
        transform: rotateZ(180deg);
      }
    }
  }
}

.tool-dropdown--kefta {
  .btn-toggle-tool-panel {
    padding: ((($touch-target-size-base - $hamburger-size) / 2) - 0.1rem);
    width: 4rem;
  }

  // Active button
  &.tool-dropdown--open {
    .btn-toggle-tool-panel {
      background-color: $color-primary;
      color: white;

      :deep(> .icon) {
        transform: rotateZ(90deg);
      }

      @include hocus() {
        background-color: $color-primary;
        color: white;
      }

      &::before {
        background-color: rgba(white, 0.25);
      }
    }
  }
}

.tool-dropdown-nav {
  overflow: hidden;
  position: absolute;
  width: 24rem;
  right: 0;
  top: 4rem + $gutter-half;
  border-radius: $border-radius-base;

  transform: translateY(-$gutter-double);
  opacity: 0;
  pointer-events: none; // Let events pass through
  transition: all 0.25s $ease-in-out;

  background-color: white;
  @include shadow(3);
  z-index: +10;

  .tool-dropdown--align-left & {
    right: auto;
    left: 0;
  }

  .tool-dropdown--open & {
    transform: translateY(0);
    opacity: 1;
    pointer-events: initial;
  }
}
</style>
