<template>
  <div class="tabs-material-subtitle no-padding-bottom">
    <Container>
      <div class="title-with-button">
        <h2>Calendrier des notations</h2>
        <Btn color="primary" hollow text="Créer un événement" @click="openModal('createEvent')" />
      </div>
    </Container>
  </div>

  <Section>
    <List :key="componentKey" :api="list.api" :header="list.headers" :items="list.col" :item-key="list.key"
      disableCheckbox>
      <template v-slot:designation="{ item }">
        <template v-if="item.designation">
          {{ item.designation }}
        </template>
        <template v-else>
          —
        </template>
      </template>
      <template v-slot:date_previsionnelle="{ item }">
        <template v-if="item.date_previsionnelle">
          {{ helperService.displayDate(item?.date_previsionnelle) }}
        </template>
        <template v-else-if="item?.stade">
          {{ item?.stade?.code_bbch }} - {{
            item.stade.designation_court ?
            item.stade.designation_court :
            item.stade.designation
          }}
        </template>
        <template v-else>
          —
        </template>
      </template>
      <template v-slot:type="{ item }">
        {{ item.type.designation }}
      </template>
      <template v-slot:actions="{ item }">
        <ToolDropdown>
          <MenuItem :item-data="{
            label: 'Consulter',
            route: {
              name: 'protocolExperimentalTaskGeneralInformation',
              params: {
                id: $route.params.id,
                tid: item.id
              }
            },
            iconName: 'visibility',
          }" />
          <MenuItem :item-data="{
            label: 'Éditer',
            route: {
              name: 'protocolExperimentalTaskGeneralInformationEdit',
              params: {
                id: $route.params.id,
                tid: item.id
              }
            },
            iconName: 'create',
          }" />
          <!--          <MenuItem
            :item-data="{
                label: 'Supprimer',
                iconName: 'trash-alt',
            }"
            @click="openModal('deleteEvent', item)"
          />-->
        </ToolDropdown>

      </template>
    </List>
  </Section>

  <Modal title="Créer un événement" :active="modal.createEvent" :data="modalData" @modal-close="modal.createEvent = false"
    containForm :modalValidationSchema="validationSchema[5]" @modal-form-submitted="createEvent($event)" no-scroll>
    <template v-slot:modal-body>
      <SelectExtended v-if="event_types.length" v-model="form.experimentalTask" label="Tâche expérimentale"
        optionKey="uid" optionValue="designation" :items="event_types" required />

      <Input id="designation" label="Désignation" required />

      <Input id="date_previsionnelle" label="Date prévisionnelle" type="date" />
      <p v-if="stade.apiParams?.filters" class="h6">Ou</p>
      <SelectExtended v-if="stade.apiParams?.filters"
        id="stade_uid"
        label="Stade prévisionnel"
        optionKey="uid"
        optionValue="designation_calculee"
        apiEndpoint="dictionnaire/stade" :apiParams="stade.apiParams" />
      <Input id="observations"
        label="Observations"
        type="textarea" />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.createEvent = false" />
      <Btn btnType="submit" text="Créer" color="primary" />
    </template>
  </Modal>

  <!-- Modals modalDeleteObservation-->
  <Modal title="Supprimer" :active="modal.deleteEvent" :data="modalData" modalAction="delete"
    @modal-close="modal.deleteEvent = false">
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment supprimer cet événement&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteEvent = false" />
      <Btn text="Supprimer" @click="deleteEvent(data)" color="primary" />
    </template>
  </Modal>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'
import Modal from '@/components/layout/Modal.vue'
import Btn from '@/components/base/Btn.vue'
import Container from '@/components/layout/Container.vue'
import List from '@/components/list/List.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'RatingCalendarAddEditSubview',

  components: {
    SelectExtended,
    MenuItem,
    ToolDropdown,
    List,
    Input,
    Section,
    Modal,
    Btn,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    validationSchema: {
      type: Array,
      default: undefined,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data(props) {
    const { validationSchema } = props
    const vs = validationSchema[0].concat(validationSchema[5])
    const { isSubmitting, errors } = this.formService.initFrom(
      vs,
    )
    return {
      // Schema
      vs,
      isSubmitting,
      errors,
      schema: {},

      protocol: {
        bpe: false,
      },
      step: 7,
      routeName: this.$route.name,
      previousRouteName: null,
      nextRouteName: null,
      method: 'post',
      componentKey: 0,

      // Data
      event_types: [],
      // Modal
      modalData: {},
      modal: {
        createEvent: false,
        deleteEvent: false,
      },
      // Modal form
      form: {
        experimentalTask: {},
      },
      // List
      list: {
        api: {
          endpoint: `protocole/${this.$route.params.id}/evenement`,
          params: {
            sort: null,
            page: null,
            limit: 0,
            filters: null,
          },
        },
        headers: [
          { label: 'Date ou stade' },
          { label: 'Type d\'évenement' },
          { label: 'Désignation' },
        ],
        col: ['date_previsionnelle', 'type', 'designation'],
        key: 'id',
      },
      stade: {
        apiParams: {
          sort: 'ordre.ASC',
        },
      },
    }
  },

  emits: [
    'set-step',
    'populate',
    'set-route',
    'form-submitted',
    'set-submitted',
  ],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.previousRouteName = from.name
    })
  },

  computed: {
    isBpe: {
      get() {
        return (this.protocol.bpe) ?? false
      },
      set(v) {
        this.protocol = v
        this.componentKey += 1
      },
    },
    isShared() {
      return (this.protocol.date_partage !== null && this.protocol.partage_accepte)
    },
  },

  watch: {
    modelValue(newVal) {
      // watch it
      if (newVal) {
        this.$emit('form-submitted', this.formService.values)
      }
    },
  },

  async mounted() {
    this.emitter.emit('open-loader')
    const routeParams = {}

    if (this.$route.params.id) {
      routeParams.id = this.$route.params.id
    }

    if (this.$route.name.includes('Edit')) {
      this.previousRouteName = 'protocolStatisticalTestsEdit'
      this.nextRouteName = 'protocolVersionsAndValidationEdit'
    } else {
      this.previousRouteName = 'protocolStatisticalTestsAdd'
      this.nextRouteName = 'protocolVersionsAndValidationAdd'
    }

    this.$emit('set-step', this.step)
    this.$emit('set-route', {
      routeName: this.routeName,
      previousRouteName: this.previousRouteName,
      nextRouteName: this.nextRouteName,
      routeParams,
    })
    this.protocol = await this.getProtocol()
    this.getEventTypes()
    this.$emit('populate', this.protocol)
    this.emitter.emit('close-loader')
  },

  methods: {
    openModal(modal, data = {}) {
      this.modalData = data
      this.modal[modal] = true
    },

    async getProtocol() {
      const response = await this.fetchService.get(`protocole/${this.$route.params.id}`)
      const protocol = await response.data
      this.isBpe = protocol

      const cultures = protocol.cultures.map((item) => item.uid)
      if (cultures.length > 0) {
        this.stade.apiParams = {
          sort: 'ordre.ASC',
          filters: `culture.uid:in(${cultures})`,
        }
      }

      return protocol
    },

    getEventTypes() {
      this.fetchService.get('dictionnaire/type/evenement?sort=designation.ASC').then(
        (response) => {
          this.event_types = response.data.filter((et) => et.uid !== 'TRAITEMENTFERTI'
            && et.uid !== 'TRAITEMENTPHYTO'
            && et.uid !== 'REMARQUE'
            && et.uid !== 'SEMIS'
            && et.uid !== 'TRAITEMENTHORSPROTOCOLE')
        },
      )
    },

    createEvent(event) {
      this.handleSubmit(event)
    },

    handleSubmit(values) {
      this.emitter.emit('open-loader')
      console.log('values', values)

      const params = { ...values }

      params.type_uid = this.form.experimentalTask.key
      params.stade_uid = values?.stade_uid?.key
      params.date_previsionnelle = this.helperService.formatDateForApi(
        params?.date_previsionnelle,
      )

      this.fetchService
        .post(`protocole/${this.$route.params.id}/evenement`, params)
        .then((response) => {
          console.log(response)

          this.form.experimentalTask = {}
          this.componentKey += 1
          this.modal.createEvent = false
          this.emitter.emit('close-loader')
        })
    },

    /* deleteEvent(item) {
      console.log('item', item)
      this.emitter.emit('open-loader')

      this.fetchService
        .delete(`protocole/${this.$route.params.id}/evenement/${item.id}`)
        .then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'L\'événement a bien été supprimée.',
            })

            this.form.experimentalTask = {}
            this.modalData = {}
            this.componentKey += 1
            this.modal.deleteEvent = false
            this.emitter.emit('close-loader')
          },
          (responseError) => {
            this.formService.handleApiError(responseError)
            this.emitter.emit('close-loader')
          },
        )
    }, */
  },
}
</script>

<style lang="scss" scoped>
.key-values-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $gutter-half;
  margin-top: $gutter-and-half;
  margin-bottom: $gutter-half;

  >h3 {
    flex-grow: 1;
    margin-bottom: 0;
  }
}
</style>
