<template>
  <div
    class="list-col"
    :class="{
      'list-col--grow': grow,
      'list-col--shrink': shrink,
      'list-col--select': select,
      'list-col--actions': actions,
      'list-col--sortable': sortable,
      'list-col--group-actions': groupActions,
      'list-col--group-actions-fake': groupActionsFake,
      'list-col--hidden-tablet': hidden === 'tablet',
      'list-col--hidden-mobile': hidden === 'mobile',
      'list-col--is-link': isLink,
    }"
  >
    <template v-if="title && !sortable">
      <span>{{ title }}</span>
    </template>
    <template v-if="title && sortable">
      <Btn
        @click="changeSort"
        :icon="getSortIconName()"
        :text="title"
        block
        iconAfter
      />
    </template>
    <slot></slot>
  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'

const sortIconName = { ASC: 'arrow-up', DESC: 'arrow-down' }
// const sortDirection = ['ASC', 'DESC']

export default {
  name: 'Col',

  components: {
    Btn,
  },

  props: {
    title: {
      type: String,
    },
    grow: {
      type: Boolean,
      default: false,
    },
    shrink: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    sortField: {
      type: String,
      default: null,
    },
    defaultSortquery: {
      type: [Object, String],
      default: null,
    },
    groupActions: {
      type: Boolean,
      default: false,
    },
    groupActionsFake: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: String,
      default: undefined,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentSortDirection: 'ASC',
      currentSortField: '',
    }
  },

  created() {
    if (this.sortable) {
      this.decodeQueryParams()
    }
  },

  methods: {
    changeSort() {
      if (this.currentSortField === this.sortField) {
        this.currentSortDirection = this.currentSortDirection === 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.currentSortField = this.sortField
        this.currentSortDirection = 'ASC'
      }

      const params = {
        sort: this.encodeQueryParams(),
      }

      this.$router.replace({
        query: { ...this.$route.query, sort: params.sort },
      })

      this.emitter.emit('list-update-params', params)
    },
    decodeQueryParams() {
      const sortQuery = this.$route.query.sort ?? this.defaultSortquery

      const sortPrams = sortQuery.split(',')
      const localArrayParam = []
      let localDirectionParam = ''

      sortPrams.forEach((sortPram) => {
        const [paramName, paramDirection] = sortPram.split('.')
        localArrayParam.push(paramName)
        localDirectionParam = paramDirection
      })

      this.currentSortField = localArrayParam.join(',')
      this.currentSortDirection = localDirectionParam
    },
    encodeQueryParams() {
      const sortQuery = []
      const sortPrams = this.currentSortField.split(',')

      sortPrams.forEach((sortPram) => {
        sortQuery.push(`${sortPram}.${this.currentSortDirection}`)
      })

      return sortQuery.join(',')
    },
    getSortIconName() {
      if (this.currentSortField === this.sortField) {
        return sortIconName[this.currentSortDirection]
      }
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
/* COL */

.list-col {
  display: table-cell;
  vertical-align: middle;
  padding: $gutter-half;
  border-top: 1px solid $color-gray-lighter;

  .input-block {
    margin-bottom: 0;
  }

  .list-row--head & {
    @include v-padding($gutter-eighth);
    border-top: none;
  }

  @include bp($breakpoint-sidebar-compact) {
    &:first-child {
      padding-left: $gutter;
    }
    &:last-child {
      padding-right: $gutter;
    }
  }

  ::v-deep(.essai-bpe) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $gutter-half;
  }

  ::v-deep(.essai-bpe-icon) {
    color: $color-primary;
  }

}

.list-col--sortable {
  ::v-deep(.btn) {
    text-decoration: underline;
  }
}

.list-col--grow {
  width: 100%; // /!\ Hacky: force growing on table cell
}

.list-col--shrink {
  width: 0%; // /!\ Hacky: force shrinking on table cell
}

.list-col--actions {
  text-align: right;
  display: flex;
  gap: $gutter-eighth;
  justify-content: flex-end;

  > * {
    flex-shrink: 0;
  }

  .list-row--head & {
    display: table-cell;
  }
}

.list-col--group-actions {
  position: absolute;
  top: 0;
  left: $input-size-radiobox + $gutter-half + $gutter-half;

  @include bp($breakpoint-sidebar-compact) {
    left: $input-size-radiobox + $gutter + $gutter-half;
  }

  right: 0;
  height: 100%;
  background-color: white;
  z-index: +1;
}

.list-col--group-actions-fake {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba($color: red, $alpha: 0.75);
}

// Reponsive visibility

.list-col--hidden-tablet {
  @include bp("md", true) {
    display: none !important;
  }
}

.list-col--hidden-mobile {
  @include bp("sm", true) {
    display: none !important;
  }
}

.list-col--is-link {
  :deep(> a) {
    color: $body-color-base;
    text-decoration: underline;
  }
}
</style>
