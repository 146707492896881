<template>
  <div class="list">
    <div class="list-header" v-if="title">{{ title }}</div>
    <div class="list-header" v-else>
      <slot name="header"></slot>
    </div>
    <div v-if="!loading && data.length > 0" class="list-body">
      <thead>
        <!-- Slot for custom headers -->
        <Row head>
          <Col shrink v-if="isDraggable">
          </Col>
          <!-- Select all -->
          <template v-if="disableGlobalCheckbox">
            <Col shrink />
          </template>
          <template v-else>
            <Col select shrink v-if="!disableCheckbox">
            <Checkbox id="checkbox-select-all" name="checkAll" :items="[{ label: '', value: 1 }]"
              :modelValue="allItemsSelected" @update:modelValue="checkAllItems" />
            </Col>
          </template>

          <!-- Group action buttons -->
          <Col group-actions v-if="selectedItems.length !== 0 && !disableGroupActions">
          <div class="list-group-actions">
            <slot name="group-actions" :selectedItems="selectedItems"></slot>
          </div>
          </Col>

          <!-- Table columns -->
          <!-- <template v-if="selectedItems.length === 0 || disableGroupActions"> -->
          <template v-for="(column, index) in header" :key="index">
            <Col v-if="column.dataField" :title="column.label" :grow="column.grow" :shrink="column.shrink"
              :hidden="column.hidden" :sortField="column.dataField" :defaultSortquery="defaultSort" sortable />

            <Col v-else :title="column.label" :grow="column.grow" :shrink="column.shrink" :hidden="column.hidden" />
          </template>

          <!-- Actions -->
          <Col v-if="!disableActions" title="Actions" actions shrink />
          <!-- </template> -->
        </Row>
      </thead>

      <!-- Table body content -->
      <tbody v-if="!isDraggable && !loading">
        <Row v-for="(element, j) in data" :key="element[itemKey]" >
          <!-- Checkbox -->
          <Col select v-if="!disableCheckbox">
          <slot name="checkbox" :item="element">
            <Checkbox :modelValue="selectedItems" :name="`list-item-${element[itemKey]}`"
              :id="`checkbox-${element[itemKey]}`"
              :items="loopOn === 'droits' ? [{ value: element[itemKey] }] : [{ value: element }]"
              @update:modelValue="onCheckboxChange($event, element)" />
          </slot>
          </Col>

          <!-- Group action fake column -->
          <Col group-actions-fake v-if="selectedItems.length !== 0 && !disableGroupActions" />

          <Col v-for="(property, index) in items" :key="index" :hidden="header[index].hidden"
            :isLink="header[index].isLink">
          <!-- Dynamic slot -->
          <template v-if="header[index].isLink">
            <router-link :to="element.route">
              <slot :name="property" :item="element"  :index="j">
                {{ element[property] }}
              </slot>
            </router-link>
          </template>
          <template v-else>
            <slot :name="property" :item="element" :index="j">
              {{ element[property] }}
            </slot>
          </template>

          </Col>

          <!-- Actions -->
          <Col v-if="!disableActions" actions>
          <slot name="actions" :item="element"></slot>
          </Col>
        </Row>
      </tbody>

      <Draggable v-if="isDraggable && !loading" tag="transition-group" :component-data="{
      tag: 'tbody',
      type: 'transition-group',
      name: !isDragging ? 'flip-list' : null
    }" v-model="data" @start="isDragging = true" @end="isDragging = false" item-key="id" handle=".drag-handle"
        v-bind="dragOptions()">

        <template #item="{ element }">
          <Row>
            <!-- Drag'n'drop handle -->
            <Col shrink v-if="isDraggable" class="drag-handle">
            <SvgIcon class="icon--drag-handle" name="grip-vertical" />
            </Col>

            <!-- Checkbox -->
            <Col select v-if="!disableCheckbox">
            <slot name="checkbox" :item="element">
              <Checkbox :modelValue="selectedItems" :name="`list-item-${element[itemKey]}`"
                :id="`checkbox-${element[itemKey]}`"
                :items="loopOn === 'droits' ? [{ value: element[itemKey] }] : [{ value: element }]"
                @update:modelValue="onCheckboxChange($event, element)" />
            </slot>
            </Col>

            <!-- Group action fake column -->
            <Col group-actions-fake v-if="selectedItems.length !== 0 && !disableGroupActions" />

            <Col v-for="(property, index) in items" :key="index" :hidden="header[index].hidden"
              :isLink="header[index].isLink">
            <!-- Dynamic slot -->
            <template v-if="header[index].isLink">
              <router-link :to="element.route">
                <slot :name="property" :item="element" :index="index">
                  {{ element[property] }}
                </slot>
              </router-link>
            </template>
            <template v-else>
              <slot :name="property" :item="element"  :index="index">
                {{ element[property] }}
              </slot>
            </template>

            </Col>

            <!-- Actions -->
            <Col v-if="!disableActions" actions>
            <slot name="actions" :item="element"></slot>
            </Col>
          </Row>
        </template>

      </Draggable>

      <!-- Animated loader -->
      <Loader :active="loading" />
    </div>
    <div v-else class="list-body list-body__empty">
      <p v-if="error" v-text="error" />
      <p v-else>Aucun élément dans cette liste.</p>
      <Loader :active="loading" />
    </div>
    <div class="list-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import Row from '@/components/list/Row.vue'
import Col from '@/components/list/Col.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import Loader from '@/components/layout/Loader.vue'
import Draggable from 'vuedraggable'
import SvgIcon from '@/components/base/SvgIcon.vue'

export default {
  name: 'List',
  components: {
    Loader,
    Row,
    Col,
    Checkbox,
    Draggable,
    SvgIcon,
  },

  emits: ['selected-items', 'list-drag-change', 'list-send-data'],

  props: {
    /**
     * Title of the list
     */
    title: {
      type: String,
    },
    /**
     * API endpoint and params to populate the list
     * @example api: {
          endpoint: "utilisateur",
          params: {
            sort: "nom.ASC,prenom.DESC",
            page: null,
            limit: null,
            filter:null
          },
        },
     */
    api: {
      type: Object,
      default: null,
    },

    listData: {
      type: Array,
      default: null,
    },
    /**
     * Used for the columns of the list
     *
     * @example ['Nom', 'Prénom', 'Profil de droit', ...]
     */
    header: {
      type: Object,
    },
    /**
     * Key used for the loop
     *
     * @default uid
     * @example id, uid
     */
    itemKey: {
      type: String,
      default: 'uid',
    },
    /**
     * Property of the rows of the table that are returned by the API
     *
     * @example ['nom', 'prenom', 'email', ...]
     */
    items: {
      type: [Array, Object],
    },

    defaultSelectedItems: {
      type: Array,
      default: null,
    },

    loopOn: {
      type: String,
    },
    /**
     * Simple list or list with checkbox
     */
    disableActions: {
      type: Boolean,
      default: false,
    },
    disableCheckbox: {
      type: Boolean,
      default: false,
    },
    disableGlobalCheckbox: {
      type: Boolean,
      default: false,
    },
    disableGroupActions: {
      type: Boolean,
      default: false,
    },
    disableChangesOn: {
      type: Array,
      default: null,
    },
    target: {
      type: Object,
      default: null,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    withNums: {
      type: Boolean,
      default: false,
    },
    defaultFilter: {
      type: Object,
      default: null,
    },
    isGrouping: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forced_datas: this.api?.forced_datas,
      selectedItems: [],
      allItemsSelected: [],
      data: [],
      meta: [],
      error: '',
      loading: false,
      endpoint: this.api?.endpoint,
      params: this.api?.params ?? {},
      forcedFilters: this.api?.params?.filters,
      targetParams: this.target,
      isDragging: false,
      listUpdateParams: false,
    }
  },
  watch: {
    /**
     * Used in case the endpoint changes
     * @example Role profiles
     */
    // eslint-disable-next-line func-names
    '$props.api.endpoint': function () {
      this.endpoint = this.api.endpoint
      this.selectedItems = []
      this.setData()
    },
    data() {
      this.$emit('list-drag-change', this.data)
    },
  },
  created() {
    if (this.params) {
      this.params.limit = this.api?.params?.limit ?? process.env.VUE_APP_LIST_LIMIT
      this.params.page = this.api?.params?.page ?? 1
      this.params.sort = this.api?.params?.sort ?? this.api?.key
      this.params.page = Number(this.$route.query.page ?? this.params.page)
    }

    this.defaultSort = this.api?.params?.sort ?? this.api?.key
    this.emitter.on('list-update-params', this.updateParams)
    this.emitter.on('list-refresh', this.setData)
  },
  mounted() {
    // event listening
    this.$emit('selected-items', this.selectedItems)
    if (this.defaultFilter) {
      this.updateParams(this.defaultFilter)
    } else if (
      !(this.$route.query.search && this.$route.query.search !== '')
      && !(this.$route.query.filters && this.$route.query.filters !== '')
    ) {
      this.setData()
    }
  },
  unmounted() {
    this.endpoint = null
  },
  methods: {
    setData() {
      if (this.loading) {
        return
      }
      this.loading = true
      this.selectedItems = []

      const localParams = {}

      if (this.params) {
        Object.entries(this.params).forEach(([key]) => {
          if (this.params[key] !== undefined && this.params[key] !== null) {
            localParams[key] = this.params[key]
          }
        })
      }

      if (
        this.forcedFilters !== null
        && this.forcedFilters !== localParams.filters
      ) {
        if (localParams.filters) {
          localParams.filters += `|n|${this.forcedFilters}`
        } else {
          localParams.filters = this.forcedFilters
        }
      }

      if (this.endpoint) {
        this.fetchService.get(this.endpoint, localParams).then(
          (response) => {
            // Determines whether to loop on a subarray or not
            if (this.loopOn === undefined) {
              this.data = response.data
            } else {
              this.data = response.data[this.loopOn]
            }

            this.data.forEach((d) => {
              if (this.target) {
                let targetParams = {}
                if (this.target?.route?.paramsFromRoute) {
                  targetParams = { ...this.target.route.paramsFromRoute }
                }
                if (this.target?.route?.itemParams) {
                  Object.keys(this.target.route.itemParams).forEach((param) => {
                    targetParams[param] = d[this.target.route.itemParams[param]]
                  })
                }
                // eslint-disable-next-line no-param-reassign
                d.route = {
                  name: this.target?.route?.name,
                  params: targetParams,
                }
              }
            })

            if (this.isGrouping) {
              if (!localParams.filters) {
                this.$emit('list-send-data', { items: this.data })
              }

              this.$emit('selected-items', { selected: this.data })
            }

            this.meta = response.meta
            if ('pagination' in this.meta) {
              this.emitter.emit('list-send-meta', this.meta)
            }

            this.hasDefaultSelectedItems()

            this.error = ''
            this.loading = false
          },
          (responseError) => {
            this.error = 'Une erreur est survenue.'
            if (responseError) {
              this.error = responseError
            }
            this.loading = false
            this.listUpdateParams = false
          },
        )
      } else {
        if (this.listData) {
          this.data = this.listData
        }
        if (this.forced_datas) {
          this.data = this.forced_datas
        }

        this.listUpdateParams = false
        this.hasDefaultSelectedItems()
        this.loading = false
      }
    },
    updateParams(params) {
      this.listUpdateParams = true
      Object.entries(params).forEach(([key]) => {
        if (this.params) {
          this.params[key] = params[key]
        }
      })

      this.setData()
    },
    checkAllItems() {
      if (!this.isAllItemChecked()) {
        this.selectedItems = this.data.map((item) => {
          let data = null
          if (this.disableChangesOn) {
            if (!this.disableChangesOn.includes(item)) {
              data = item
            }
          } else {
            data = item
            if (this.loopOn === 'droits') {
              data = item[this.itemKey]
            }
          }
          return data
        })
      } else {
        this.selectedItems = []
      }
      if (!this.loading) {
        this.$emit('selected-items', { selected: this.selectedItems })
      }
    },
    onCheckboxChange(event, item) {
      let index = this.selectedItems.findIndex((element) => element[this.itemKey] === item[this.itemKey])
      if (this.loopOn === 'droits') {
        index = this.selectedItems.findIndex((element) => element === item[this.itemKey])
      }
      if (index > -1) {
        this.selectedItems.splice(index, 1)
      } else if (this.loopOn === 'droits') {
        this.selectedItems.push(item[this.itemKey] ? item[this.itemKey] : item)
      } else {
        this.selectedItems.push(item)
      }

      if (this.isAllItemChecked()) {
        this.allItemsSelected = [1]
      } else {
        this.allItemsSelected = []
      }
      if (!this.loading) {
        this.$emit('selected-items', { selected: this.selectedItems })
      }
    },
    isAllItemChecked() {
      let isAllIn = true
      let toSave = null
      if (this.disableChangesOn) {
        toSave = this.data.filter((item) => !this.disableChangesOn.includes(item))
      }
      if (toSave) {
        toSave.forEach((item) => {
          if (!this.selectedItems.includes(item)) {
            isAllIn = false
          }
        })
      } else {
        let datas = this.data
        if (this.loopOn === 'droits') {
          datas = this.data.map((d) => d.uid)
        }
        datas.forEach((item) => {
          if (!this.selectedItems.includes(item)) {
            isAllIn = false
          }
        })
      }

      return isAllIn
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    /**
     * Checks if the component has default items
     */
    hasDefaultSelectedItems() {
      if (!this.isGrouping && this.defaultSelectedItems && this.defaultSelectedItems.length > 0) {
        this.defaultSelectedItems.forEach((item) => {
          this.onCheckboxChange(null, item)
        })
      }
      if (this.isGrouping && (!this.defaultSelectedItems)) {
        this.data.forEach((item) => this.onCheckboxChange(null, item))
      } else if (this.isGrouping && this.defaultSelectedItems.length > 0) {
        const ids = this.defaultSelectedItems.map((ds) => ds.id)
        this.data.forEach((item) => {
          if (ids.includes(item.id)) {
            this.onCheckboxChange(null, item)
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/* LIST */
$timeline-step-size: 2.6rem;

.list {
  // overflow-x: auto;
}

.list-body {
  display: table;
  width: 100%;
  position: relative;
  border-top: 1px solid $color-gray-lighter;

  &.list-body__empty {
    display: flex;
    padding-top: $gutter;
    justify-content: center;
  }
}

.list-row--head {
  position: relative;
  // height: 5.2rem; // Magic value!
}

.list-group-actions {
  // position: absolute;
  // height: 100%;
  // top: 0;
  display: flex;
  align-items: center;
  gap: $gutter-quarter;
  // width: 100%;
  // min-width: 50vw;
}

.drag-handle {
  cursor: grab;
  color: $color-gray-light;

  @include hocus() {
    color: $color-gray;
  }
}

.icon--drag-handle {
  display: block;
  transition: all 0.25s;
}
</style>
