<template>
  <div
    class="list-row"
    :class="{
      'list-row--head': head,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Row',

  props: {
    head: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
/* ROW */

.list-row {
  display: table-row;
  // display: grid;
  // grid-template-columns: $gutter 3fr 1fr 2fr 1fr;
  // border-top: 1px solid $color-gray-lighter;

  // &:last-child {
  //   border-bottom: 1px solid $color-gray-lighter;
  // }
}

.list-row--head {
  position: relative;
  font-weight: $font-weight-semibold;
}
</style>
